import React from "react";
import { styled } from "@mui/material/styles";
import Linkify from "react-linkify";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "@mui/material";
import { CategoryRounded } from "@mui/icons-material";
import { AnchorLink as Link } from "@prismatic-io/gatsby-plugin-anchor-links";

import { SEO } from "@/layout/SEO";
import { HomePageHeader } from "@/components/headers";
import { Markdown } from "@/components/markdown";
import { SecondaryButton } from "@/components/buttons";
import LandingPage from "@/page-components/landing";
import ComponentPack from "@/components/ComponentPack";

const PREFIX = "ComponentPage";

const classes = {
  container: `${PREFIX}-container`,
  header: `${PREFIX}-header`,
  ctaArea: `${PREFIX}-ctaArea`,
  primary: `${PREFIX}-primary`,
  secondary: `${PREFIX}-secondary`,
  callToActionButton: `${PREFIX}-callToActionButton`,
  title: `${PREFIX}-title`,
  imageContainer: `${PREFIX}-imageContainer`,
  tableContainer: `${PREFIX}-tableContainer`,
  fourPackContainer: `${PREFIX}-fourPackContainer`,
};

const ComponentPageStyled = styled("div")(({ theme }) => ({
  paddingBottom: "3em",

  [`& .${classes.header}`]: {
    position: "relative",
    margin: "0 auto",
    maxWidth: 1230,
    padding: "0 22px",
    "& h2": {
      marginBottom: 0,
    },
  },

  [`& .${classes.ctaArea}`]: {
    paddingLeft: 0,

    "& > *": {
      marginRight: "0.5em",
    },
  },

  [`& .${classes.primary}`]: {},
  [`& .${classes.secondary}`]: {},

  [`& .${classes.callToActionButton}`]: {
    borderRadius: 8,
    padding: "0.7em 2em",
    fontSize: "1.2em",
    [theme.breakpoints.down(392)]: {
      padding: "0.7em 0.5em",
    },
  },

  [`& .${classes.title}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  [`& .${classes.imageContainer}`]: {
    width: "1.7em",
    marginRight: "0.5em",

    "& img": {
      width: "1.7em",
    },
  },

  [`& .${classes.tableContainer}`]: {},

  [`& .${classes.fourPackContainer}`]: {
    marginTop: "8em",
  },
}));

const defaultDescription =
  "Prismatic's built-in components make it easy for B2B software companies to integrate with the other apps and services your customers use.";

const ComponentPage = ({ data, location, pageContext }) => {
  const {
    title,
    description,
    firstSection,
    secondSection,
    ...formData
  } = data.componentPage.frontmatter;
  const { componentData } = pageContext;
  const connector = componentData.category === "Application Connectors";
  const componentType = connector ? "Connector" : "Component";
  const titleText = `${componentData.label} ${componentType}`;

  const titleBase = (
    <div className={classes.title}>
      {data.componentImage ? (
        <div className={classes.imageContainer}>
          <GatsbyImage
            alt={`${componentData.label} Component`}
            image={data.componentImage.childImageSharp.gatsbyImageData}
            loading="eager"
            objectFit="scale-down"
          />
        </div>
      ) : null}
      {titleText}
    </div>
  );

  const connectorDescription = `Quickly integrate your B2B software product with ${componentData.label}.`;
  const descriptionBase = connector ? connectorDescription : defaultDescription;

  return (
    <LandingPage
      title={titleBase}
      description={descriptionBase}
      breadcrumbArea={
        <div>
          <SecondaryButton
            component={Link}
            to="/components/"
            startIcon={<CategoryRounded />}
            style={{ textTransform: "none" }}
          >
            Explore Our Components
          </SecondaryButton>
        </div>
      }
      callToAction={{
        primary: {
          link: "/sign-up/?plan=free",
          text: "Start for Free",
        },
        secondary: {
          link: "/request-a-demo/",
          text: "Get a Demo",
        },
      }}
      {...formData}
    >
      <ComponentPageStyled>
        <div className={classes.header}>
          <HomePageHeader>About the {titleText}</HomePageHeader>
          <Markdown html={componentData.markdown} />
          <SecondaryButton
            component="a"
            href={`/docs/components/${componentData.key}/`}
          >
            Documentation
          </SecondaryButton>
        </div>
        {componentData.actions?.length > 0 ? (
          <div className={classes.header}>
            <HomePageHeader>{componentType} Actions</HomePageHeader>
            <TableContainer className={classes.tableContainer}>
              <Table
                stickyHeader
                className={classes.table}
                aria-label="actions table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Action Name</TableCell>
                    <TableCell>Action Description</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {componentData.actions.map(({ key, name, description }) => (
                    <TableRow key={key}>
                      <TableCell>{name}</TableCell>
                      <TableCell>
                        <Linkify>{description}</Linkify>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ) : null}
        {componentData.triggers?.length > 0 ? (
          <div className={classes.header}>
            <HomePageHeader>{componentType} Triggers</HomePageHeader>
            <TableContainer className={classes.tableContainer}>
              <Table
                stickyHeader
                className={classes.table}
                aria-label="triggers table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Trigger Name</TableCell>
                    <TableCell>Trigger Description</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {componentData.triggers.map(({ key, name, description }) => (
                    <TableRow key={key}>
                      <TableCell>{name}</TableCell>
                      <TableCell>
                        <Linkify>{description}</Linkify>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ) : null}
        <div className={classes.fourPackContainer}>
          <ComponentPack />
        </div>
      </ComponentPageStyled>
    </LandingPage>
  );
};

export const query = graphql`
  query getComponentData($image: String) {
    componentImage: file(relativePath: { eq: $image }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: NONE
          quality: 85
          width: 80
        )
      }
    }
    componentPage: markdownRemark(
      fileAbsolutePath: { glob: "**/pages/component.md" }
    ) {
      frontmatter {
        ...SeoFields
        minimalNavigation
        customFooterCta {
          primary
          secondary
          primaryCta {
            text
            link
            type
          }
          secondaryCta {
            text
            link
            type
          }
        }
        sections {
          title
          callToActions {
            text
            link
            type
          }
          markdown
          media {
            appFrame
            alternateText
            caption
            video
            screenshot {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  placeholder: NONE
                  quality: 85
                  width: 650
                )
              }
            }
          }
          type
          gridItems {
            markdown
            appFrame
            alternateText
            gridImage {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  placeholder: NONE
                  quality: 85
                  width: 650
                )
              }
            }
          }
          features {
            icon
            markdown
          }
        }
        form {
          headline
          name
          text
          theme
          checklistSection
          imageSection {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: NONE
                quality: 85
                width: 550
              )
            }
          }
          testimonialSection
          markdownSection
          logoSection {
            title
            logos {
              title
              image {
                publicURL
              }
            }
          }
          submitAreaMarkdown
          submit {
            text
            loadingText
          }
          fields {
            name
            label
            type
            validation
            validationMatch
            defaultValue
            autoComplete
            identifier
            hideFromSegment
          }
          defaultParams {
            key
            value
          }
          confirmation {
            redirect {
              route
              type
            }
            primary
            secondary
            confirmationImage {
              childImageSharp {
                gatsbyImageData(
                  layout: FIXED
                  placeholder: NONE
                  quality: 85
                  width: 200
                )
              }
            }
          }
          subdomain
          route
          errorMessage
        }
      }
    }
  }
`;

export const Head = ({ location, data, params, pageContext }) => {
  const {
    author,
    robots,
    schemaOrg,
    seoImage,
    title,
  } = data.componentPage.frontmatter;
  const { componentData } = pageContext;
  const connector = componentData.category === "Application Connectors";
  const componentType = connector ? "Connector" : "Component";
  const connectorDescription = `Quickly integrate your B2B software product with ${componentData.label}.`;

  return (
    <SEO
      author={author}
      description={connector ? connectorDescription : defaultDescription}
      pathname={location.pathname}
      robots={robots}
      schemaOrg={schemaOrg}
      seoImage={seoImage}
      title={title}
      titleTag={`${componentData.label} Integration ${componentType} | Prismatic`}
      metaDescription={
        connector
          ? `${connectorDescription} API connectors, built-in authorization, and powerful tools to build exactly what you need.`
          : `${componentData.description}. ${defaultDescription}`
      }
    />
  );
};

export default ComponentPage;
